import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { usePrevious, useUpdateEffect } from "react-use";
import { bool, shape, string } from "prop-types";
import qs from "qs";

import CarConfigurator from "@components/CarConfigurator/CarConfigurator";
import CarouselAndFinanceBar from "@components/CarouselAndFinanceBar/CarouselAndFinanceBar";
import TestDriveLink from "@components/TestDrive/TestDriveLink";
import Layout from "@components/Layout/Layout";
import DesktopContainer from "@components/shared/DesktopContainer";
import Modal from "@components/shared/Modal";
import PreconfigurationModal from "@components/shared/PreconfigurationModal";
import UnavailableItemsModal from "@components/shared/UnavailableItemsModal";
import ButtonGroup from "@components/shared/ButtonGroup";
import Button from "@components/shared/Button";
import Typography from "@components/shared/Typography";

import gtm from "@gtm/core";
import { generateConfigurationUrl } from "@shared/helper/generateConfigurationURL";
import { parseConfigurationUrl } from "@shared/helper/parseConfigurationUrl";
import { useAreOptionsSelected } from "@hooks/useAreOptionsSelected";
import { useCarValidation } from "@hooks/useCarValidation";
import { useIsB2BSelected } from "@hooks/useIsB2BSelected";
import { getFinanceVariant } from "lib/getFinanceVariant";
import { ROUTES } from "@shared/routing/routes";
import { useRoutingUtils } from "@hooks/useRoutingUtils";
import { toast } from "@lib/reactToastify";
import { useIsUserTrustedSelector } from "@redux/reducers/auth";
import { setUnavailableItemsAction } from "@redux/actions/errorsActions";
import { withTranslation, useTranslation } from "@lib/i18n";
import { useGetIsTargetBusinessModelFinanceEnabled } from "hooks/useGetIsTargetBusinessModelFinanceEnabled";
import { useRemoveQueryParameters } from "@hooks/useRemoveQueryParameters";
import { setLastChangedAtAction } from "@redux/actions/deprecatedActions";
import { useLastChangedAtSelector } from "@redux/reducers/deprecated";
import { useBasketErrorCodesSelector } from "@redux/reducers/errors";
import { useActiveCarPreconfigurationSelector } from "@redux/reducers/deal";
import { changeConfigurationEvent } from "@gtm/events/homepage";
import { useFinanceWidgetErrorsSelector } from "@redux/reducers/errors";
import { getIsVACJourneySelected } from "@shared/v2/lib/getIsVACJourneySelected";

import {
  useCarSelector,
  useReferrerSelector,
  useConfigurationSelector,
  useTestDriveLinkOnHomePageSelector,
} from "@redux/reducers/appConfig";

import {
  setIsSelectionConfirmedAction,
  updateIsSelectionConfirmedAction,
  updateBusinessModelAction,
  calculatePricesAction,
  removePromoCodeAction,
} from "@redux/actions/dealActions";

import {
  IS_CLIENT,
  UPDATE_SOURCE,
  BUSINESS_MODELS,
  QUERY_ACTION_TYPES,
  REQUEST_ERROR_CODES,
  DEAL_STATES,
  CUSTOMIZE_TYPES,
  COUNTRIES,
  SESSION_STORAGE_KEYS,
} from "@shared/constants";

import {
  color,
  desktop,
  fontSize,
  largeMobile,
} from "@components/shared/utils";

import { isConfigurationAvailable } from "@shared/helper/isConfigurationAvailable";

import {
  useCarConfigurationSelector,
  useFinanceSimulationSelector,
  useCurrentDealSelector,
  useDealSelector,
  useBusinessModelSelector,
  useIsScrappageSelectedSelector,
} from "@redux/reducers/deal";

import {
  updateCarPreconfigurationAction,
  updateCarConfigurationAction,
  updateJourneyAction,
  createNewDealFromExistingAction,
} from "@redux/actions/dealActions";

import {
  homepageConfiguratorUpdateUrlEvent,
  homepageConfiguratorEcommerceEvent,
} from "gtm/events/homepage";
import { getIsCountry } from "lib/getIsCountry";
import { sessionStorageClient } from "utils/sessionStorageClient";
import api from "@api/index";

const { publicRuntimeConfig } = getConfig();

const { ROOT_URL_PREFIX } = publicRuntimeConfig;

const ModalContainer = styled.div`
  background-color: ${color("white")};
  width: 100%;
  max-width: 40rem;
  min-width: 15rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

const StyledTypography = styled(Typography)`
  font-size: ${fontSize("md")};

  ${largeMobile`
    font-size: ${fontSize("lg")}
  `}
`;

const StyledButton = styled(Button)`
  border: 1px solid currentColor;
  padding: 1rem 1rem;
  font-size: ${fontSize("sm")};

  ${largeMobile`
    font-size: ${fontSize("md")};
    padding: 1.25rem 2rem;
  `}
`;

const ContentContainer = styled.div`
  ${desktop`
     padding-top: 7rem;
  `};
`;

const Config = ({ pageProps: { deepLinkErrorMessage }, isSSRRendered }) => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const routingUtils = useRoutingUtils();
  const isUserTrusted = useIsUserTrustedSelector();
  const isB2BSelected = useIsB2BSelected();

  const {
    config: rawConfig,
    journey: journeyQuery,
    action: actionQuery,
  } = router.query;

  const config = (rawConfig || []).filter(
    configPart =>
      configPart !== ROOT_URL_PREFIX.replace("/", "") ||
      configPart !== BUSINESS_MODELS.B2B
  );

  const dispatch = useDispatch();
  const carObject = useCarSelector();
  const currentDeal = useCurrentDealSelector();
  const deal = useDealSelector();
  const appConfiguration = useConfigurationSelector();
  const { areOptionsSelected } = useAreOptionsSelected();
  const { preconfigurations, services } = carObject;
  const [isInvalidUrlModalOpen, setIsInvalidUrlModalOpen] = useState(
    !!deepLinkErrorMessage
  );

  const [isChangePreconfigModalOpen, setIsChangePreconfigModalOpen] = useState(
    false
  );

  const [
    isUnavailableItemsModalOpen,
    setIsUnavailableItemsModalOpen,
  ] = useState(false);

  const [fallBackPreconfiguration, setFallBackPreconfiguration] = useState(
    null
  );
  const [shouldUpdateUrl, setShouldUpdateUrl] = useState(true);

  const defaultPreconfiguration =
    preconfigurations?.find(
      preconfiguration => preconfiguration.default === true
    ) ?? preconfigurations[0];

  const currentCarConfiguration = useCarConfigurationSelector();

  const {
    accessory: selectedAccessories,
    service: selectedServices,
    preconfiguration: selectedPreconfiguration,
  } = currentCarConfiguration;

  const lastChangeAt = useLastChangedAtSelector();

  const { journey: selectedJourney } = useFinanceSimulationSelector();
  const previousJourney = usePrevious(selectedJourney);
  const previousLastChangeAt = usePrevious(lastChangeAt);
  const { financeSimulation, carConfiguration } = useDealSelector();
  const basketErrorCodes = useBasketErrorCodesSelector();
  const pageVariant = getFinanceVariant(financeSimulation.journey);
  const affiliation = useReferrerSelector();
  const preconfigurationLabel = carConfiguration.preconfiguration.label;
  const selectedBusinessModel = useBusinessModelSelector();
  const isScrappageSelected = useIsScrappageSelectedSelector();
  const removeQueryParameters = useRemoveQueryParameters();
  const getIsTargetBusinessModelFinanceEnabled = useGetIsTargetBusinessModelFinanceEnabled();
  const financeWidgetErrors = useFinanceWidgetErrorsSelector();
  const isVACJourneySelected = getIsVACJourneySelected(selectedJourney);
  const isTestDriveLinkOnHomePage = useTestDriveLinkOnHomePageSelector();

  const {
    id: activePreconfigurationId,
    status: activePreconfigurationStatus,
  } = useActiveCarPreconfigurationSelector();

  const { unavailableItems } = useCarValidation(deal);

  const redirectToBasket = () =>
    router.push(
      ROUTES.BASKET,
      routingUtils.getLocalizedPathname(ROUTES.BASKET, null, i18n.language)
    );

  const handleRedirectToBasket = async (
    currentDeal = null,
    areOptionsSelected = false
  ) => {
    if (currentDeal && areOptionsSelected) {
      setIsChangePreconfigModalOpen(true);
      return;
    }

    if (
      [DEAL_STATES.OFFER, DEAL_STATES.ORDER].includes(currentDeal?.dealState)
    ) {
      await dispatch(createNewDealFromExistingAction());
    }

    setShouldUpdateUrl(false);
    await applyConfigurationToState();
    dispatch(setIsSelectionConfirmedAction(true));
    redirectToBasket();
  };

  useEffect(() => {
    if (!isSSRRendered) {
      dispatch(calculatePricesAction());
    }
  }, []);

  useEffect(() => {
    if (deepLinkErrorMessage) {
      console.log("🗿 [Deep Link Error]", deepLinkErrorMessage);
    }
  }, []);

  useEffect(() => {
    const shouldRedirectToBasket =
      actionQuery === QUERY_ACTION_TYPES.SHARE_CONFIGURATION;

    gtm.fire(
      homepageConfiguratorUpdateUrlEvent({
        pageVariant,
        pageTitle: router.asPath,
        affiliation,
        isB2BSelected,
        isSSRRendered,
      })
    );

    gtm.fire(
      homepageConfiguratorEcommerceEvent({
        affiliation,
        price: financeSimulation.totalPrice.cash.netPrice,
        variant: selectedPreconfiguration.label,
        isB2BSelected,
      })
    );

    const {
      isValidUrl,
      isValidPreconfigurationFromUrl,
      validatedPreconfiguration,
      selectedBusinessModel,
    } = parseConfigurationUrl(
      appConfiguration,
      config,
      carObject,
      router.asPath,
      journeyQuery ?? selectedJourney
    );

    if (isValidUrl) {
      if (shouldRedirectToBasket) {
        handleRedirectToBasket(currentDeal, areOptionsSelected);
        return;
      }

      dispatch(updateBusinessModelAction(selectedBusinessModel));
    } else {
      if (isValidPreconfigurationFromUrl) {
        setFallBackPreconfiguration(validatedPreconfiguration);
      }

      setIsInvalidUrlModalOpen(true);
    }
  }, []);

  useUpdateEffect(() => {
    const journeyTypeHasChanged = previousJourney !== selectedJourney;

    const configurationHasChanged = previousLastChangeAt !== lastChangeAt;

    if (shouldUpdateUrl && (journeyTypeHasChanged || configurationHasChanged)) {
      applyConfigurationToUrl();
    }

    // This behaviour is requested only for spain
    if (getIsCountry(COUNTRIES.ES)) {
      setIsFinancingOpen(getIsVACJourneySelected(selectedJourney));
    }
  }, [
    shouldUpdateUrl,
    lastChangeAt,
    previousLastChangeAt,
    selectedJourney,
    previousJourney,
  ]);

  useEffect(() => {
    applyConfigurationToUrl();
  }, [selectedBusinessModel, isScrappageSelected]);

  const applyConfigurationToUrl = partialConfiguration => {
    const [asPathBase, configurationQuery] = generateConfigurationUrl({
      selectedLanguage: i18n.language,

      appConfiguration,
      selectedAccessories,
      selectedPreconfiguration,
      selectedJourney,
      selectedBusinessModel,
      selectedServices,
      isScrappageSelected,

      ...partialConfiguration,
    }).split("?");

    const currentSearchParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    const generatedSearchParams = qs.parse(configurationQuery);

    const nextQueryParams = {
      ...currentSearchParams,
      ...generatedSearchParams,
    };

    const asPath =
      asPathBase +
      qs.stringify(nextQueryParams, {
        addQueryPrefix: true,
        skipNulls: true,
      });

    router.replace(ROUTES.CONFIG, asPath, {
      shallow: true,
    });
  };

  const applyConfigurationToState = async () => {
    const { salesman } = deal;

    const {
      validatedAccessories,
      validatedServices,
      validatedPreconfiguration,
      validJourneyQuery,
      selectedBusinessModel,
    } = parseConfigurationUrl(
      appConfiguration,
      config,
      carObject,
      router.asPath,
      journeyQuery ?? selectedJourney
    );

    const filteredAccessories = validatedAccessories.filter(
      accessory =>
        !accessory.salesmanBlacklist.includes(router.query.salesman ?? salesman)
    );

    dispatch(updateBusinessModelAction(selectedBusinessModel));

    await dispatch(
      updateCarPreconfigurationAction(validatedPreconfiguration, false)
    );

    await dispatch(
      updateCarConfigurationAction({
        accessory: filteredAccessories,
        service: validatedServices,
      })
    );

    if (getIsTargetBusinessModelFinanceEnabled(selectedBusinessModel)) {
      await dispatch(updateJourneyAction(validJourneyQuery));
    } else {
      removeQueryParameters(["journey"]);
    }

    if (validatedAccessories.length !== filteredAccessories.length) {
      applyConfigurationToUrl({ selectedAccessories: filteredAccessories });
    }
  };

  const handleConfirmChangePreconfiguration = async () => {
    setShouldUpdateUrl(false);
    setIsChangePreconfigModalOpen(false);

    await Promise.all([
      dispatch(updateIsSelectionConfirmedAction(true)),
      applyConfigurationToState(),
    ]);

    redirectToBasket();
  };

  const handleCancelChangePreconfiguration = () => {
    setIsChangePreconfigModalOpen(false);
    applyConfigurationToUrl();
  };

  const onConfirmRemoveUnavailableItems = async () => {
    setShouldUpdateUrl(false);
    setIsUnavailableItemsModalOpen(false);

    const isUnavailableItemPack = unavailableItems.some(
      unavailableItem => unavailableItem.type === CUSTOMIZE_TYPES.PACK
    );

    if (activePreconfigurationStatus === false || isUnavailableItemPack) {
      const unavailableItemIds = unavailableItems.map(
        unavailableItem => unavailableItem.id
      );

      const availablePreconfiguration = preconfigurations.find(
        preconfiguration =>
          isConfigurationAvailable(isB2BSelected)(preconfiguration) &&
          preconfiguration.options.every(
            optionId => !unavailableItemIds.includes(optionId)
          )
      );

      if (!availablePreconfiguration) {
        toast.error(
          t("notify.apiDefaultError"),
          {
            toastId: "api-error",
          },
          "There is no available configuration"
        );

        return;
      }

      dispatch(updateCarPreconfigurationAction(availablePreconfiguration));
      dispatch(setLastChangedAtAction(Date.now()));

      gtm.fire(changeConfigurationEvent(availablePreconfiguration.label));
    }

    const isUnavailableItemAccessory = unavailableItems.some(
      unavailableItem => unavailableItem.type === CUSTOMIZE_TYPES.ACCESSORY
    );

    const isUnavailableItemService = unavailableItems.some(unavailableItem =>
      services.some(service => service.id === unavailableItem.id)
    );

    const partialConfiguration = {};
    if (isUnavailableItemAccessory) {
      dispatch(updateCarConfigurationAction({ accessory: [] }));
      partialConfiguration.selectedAccessories = [];
    }

    if (isUnavailableItemService) {
      dispatch(updateCarConfigurationAction({ service: [] }));
      partialConfiguration.selectedServices = [];
    }

    dispatch(setUnavailableItemsAction([]));
    applyConfigurationToUrl(partialConfiguration);
  };

  const onCancelRemoveUnavailableItems = () => {
    setIsUnavailableItemsModalOpen(false);
  };

  const handleNotExistingPreconfiguration = preconfiguration => {
    dispatch(updateCarPreconfigurationAction(preconfiguration));
    dispatch(setLastChangedAtAction(Date.now()));
    setIsInvalidUrlModalOpen(false);
    api.updateSession({ deepLinkErrorMessage: null });
  };

  useEffect(() => {
    if (
      basketErrorCodes?.includes(
        REQUEST_ERROR_CODES.PRECONFIGURATION_UNAVAILABLE
      ) &&
      isUserTrusted
    ) {
      sessionStorageClient.removeItem(SESSION_STORAGE_KEYS.PROMOCODE);
      dispatch(removePromoCodeAction());
      dispatch(createNewDealFromExistingAction());
    }
  }, []);

  useEffect(() => {
    if (unavailableItems.length === 0) {
      return;
    }

    setIsUnavailableItemsModalOpen(true);
  }, [activePreconfigurationId, unavailableItems]);

  const [isFinancingOpen, setIsFinancingOpen] = useState(
    financeWidgetErrors.length > 0 ||
      (getIsCountry(COUNTRIES.ES) && isVACJourneySelected)
  );

  const invalidUrlModalFirstElement = useRef(null);
  const invalidUrlModalLastElement = useRef(null);

  return (
    <Layout
      withSave
      saveProgressSource={UPDATE_SOURCE.CONFIGURATOR_SAVE_MY_CAR}
      noAnimation
      namePreconfiguration={preconfigurationLabel}
    >
      <Modal
        isOpen={isInvalidUrlModalOpen}
        firstElement={
          fallBackPreconfiguration
            ? invalidUrlModalFirstElement
            : invalidUrlModalLastElement
        }
        lastElement={invalidUrlModalLastElement}
        disableBackdropClick
        disableEscapePress
        ariaLabel={t("deeplinkConfigModal.errorMessage")}
      >
        <ModalContainer>
          <StyledTypography margin="auto 0.8rem auto">
            {t("deeplinkConfigModal.errorMessage")}
          </StyledTypography>
          <ButtonGroup justifyCenter>
            {fallBackPreconfiguration && (
              <StyledButton
                data-id="popup-configmodal-default-config"
                ref={invalidUrlModalFirstElement}
                onClick={() =>
                  handleNotExistingPreconfiguration(fallBackPreconfiguration)
                }
              >
                {t("deeplinkConfigModal.defaultPreconfig")}
              </StyledButton>
            )}
            <StyledButton
              data-id="popup-configmodal-new-config"
              ref={invalidUrlModalLastElement}
              onClick={() =>
                handleNotExistingPreconfiguration(
                  defaultPreconfiguration || preconfigurations[0]
                )
              }
            >
              {t("deeplinkConfigModal.resetConfig")}
            </StyledButton>
          </ButtonGroup>
        </ModalContainer>
      </Modal>
      <PreconfigurationModal
        isOpen={isChangePreconfigModalOpen}
        onConfirm={handleConfirmChangePreconfiguration}
        onCancel={handleCancelChangePreconfiguration}
        dataId="shared-link-preconfiguration-modal"
      />
      <UnavailableItemsModal
        isOpen={isUnavailableItemsModalOpen}
        unavailableItems={unavailableItems}
        onConfirm={onConfirmRemoveUnavailableItems}
        onCancel={onCancelRemoveUnavailableItems}
      />
      <ContentContainer>
        <DesktopContainer>
          <CarouselAndFinanceBar
            isFinancingOpen={isFinancingOpen}
            onIsFinancingOpenChange={isFinancingOpen => {
              setIsFinancingOpen(isFinancingOpen);
            }}
          />
        </DesktopContainer>
        <CarConfigurator
          isFinancingOpen={isFinancingOpen}
          onIsFinancingOpenChange={isFinancingOpen => {
            setIsFinancingOpen(isFinancingOpen);
          }}
          isPromoCodeApplicable={true}
        />
        {isTestDriveLinkOnHomePage && <TestDriveLink />}
      </ContentContainer>
    </Layout>
  );
};

const handleRedirectToBasketAction = ctx => {
  const { req, query } = ctx;
  const { action } = query;
  const { config, journey } = query;
  const { car, configuration } = req.meta.initialData;
  const { session } = req;

  const {
    isValidUrl,
    validatedPreconfiguration,
    validatedAccessories,
    validatedServices,
    validJourneyQuery,
  } = parseConfigurationUrl(
    configuration,
    config,
    car,
    ctx.asPath,
    journey ?? session.journey
  );

  if (isValidUrl && action === QUERY_ACTION_TYPES.SHARE_CONFIGURATION) {
    session.journey = validJourneyQuery;
    session.isSelectionConfirmed = true;
    session.configuration.accessories = validatedAccessories.map(
      accessory => accessory.id
    );

    if (validatedServices.length === 1) {
      session.configuration.service = validatedServices[0].id;
    }

    session.configuration.preconfiguration = validatedPreconfiguration.id;
  }
};

Config.getInitialProps = async ctx => {
  if (IS_CLIENT) {
    return { isSSRRendered: true };
  }

  const { req } = ctx;
  const {
    session,
    session: { deepLinkErrorMessage },
  } = req;

  session.isPriceLocked = false;

  // We can update the isSelectionConfirmed on SSR and save a request from the frontend..
  handleRedirectToBasketAction(ctx);

  return {
    deepLinkErrorMessage,
    namespacesRequired: ["common"],
  };
};

Config.propTypes = {
  pageProps: shape({
    deepLinkErrorMessage: string,
  }),
  isSSRRendered: bool,
};

export default withTranslation("common")(Config);
